* {
  font-family: sans-serif;
  scroll-behavior: smooth;
}
.slick-slider {
  width: 100%;
  overflow: hidden;
  height: fit-content;
}
.slick-dots {
  transform: translateY(-20vh);
}
.ql-editor {
  background-color: rgb(245, 245, 245);
  min-height: 30vh;
  padding: 2%;
}
#dangerouslySetInnerHTML p {
  font-size: larger;
  line-height: 1.55;
  margin: 1% 0%;
  font-family: Roboto Slab, Times New Roman, Times, serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  color: #1e1e1e;
}
#dangerouslySetInnerHTML li {
  font-size: large;
  line-height: 1.25;
  margin: 2% 0%;
  color: #261d1d;
}
#dangerouslySetInnerHTML iframe {
  width: 100%;
  min-height: 70vh;
}
#dangerouslySetInnerHTML img {
  max-width: 100%;
}
@keyframes dropdown {
  0% {
    clip-path: inset(0 0 100% 0);
  }
  100% {
    clip-path: inset(0 0 100% 0);
  }
}
@media screen and (max-width: 900px) {
  #nav_li {
    display: grid;
    justify-content: left;
    margin-left: 5%;
  }
  #nav_li:hover {
    transition: 0.1s ease;
    padding: 2%;
    margin: 0vh;
  }
  a.nextButton,
  a.previousButton {
    transform: none;
    margin: -9vh -4%;
    color: #a0c0ff72;
  }
  .slider a.nextButton {
    right: 0px;
  }
  .slider {
    min-height: 50vh;
    margin: 2% 0%;
  }
  #dangerouslySetInnerHTML iframe {
    width: 100%;
    min-height: 60vh;
  }
}
