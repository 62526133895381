@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Fasthand&display=swap');


* {
  padding: 0%;
  margin: 0%;
}

/* ---------- for memebrship --------- */

/* --------! important  */
.cost {
  background-color: rgb(3, 77, 3);
  font-weight: bold;
  font-size: large;
  width: 40%;
  margin: 3vh 0 3vh -25px;
  text-align: left;
  padding: 12px 7px;
  text-align:center;
  color: rgb(236, 236, 236);
}

.popular {
  width: 100%;
  background-color: gray;
  margin: 15px 0;
  width: 100px;
  color: white;
  font-size: 10px;
  padding: 5px 7px;
  margin-left: 84%;
}
@media (max-width: 700px) {
  .popular{
    margin-left: 60%;
  }  
}


/* ----------for the footer ----------------- */

.row1 {
  width: 100%;
  padding: 20px 0;
  overflow-y: hidden;
  display: flex;
  background: #d2191903;
  align-items: flex-start;
  text-align: left;
}

.first {
  width: 50%;
  margin: auto;
  padding: 5px 20px;
  font-size: 15px;
}
.first img {
  width: 200px;
}
.first p {
  font-weight: 500;
}
.row1 h2 {
  font-size: 20px;
  color: rgb(177, 64, 23);
}

/* ---- subscribe button ---- */
.first input {
  width: 50%;
  padding: 10px 10px;
  outline: none;
  border: 1px solid gray;
  border-radius: 4px;
  margin: 10px 5px 10px 0;
  font-size: 16px;
}
.first button {
  padding: 10px 5px;
  border: none;
  background: rgb(231, 68, 9);
  border-radius: 5px;
  margin-left: 10px;
  color: aliceblue;
  font-size: large;
}
.first button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.socials {
  font-size: 22px;
  margin-top: 10px;
}
.first a {
  color: rgb(10, 10, 77);
  margin-right: 20px;
}

/* ------- office ------------- */
.office_links {
  flex-basis: 40%;
  display: flex;
}
.office {
  flex-basis: 50%;
  font-size: 14px;
  line-height: 20px;
  margin-left: 15px;
}
.office h2 {
  margin-bottom: 20px;
}

/* ------links ------------ */
.links {
  flex-basis: 20%;
  font-size: 15px;
}

.links ul {
  margin-top: 20px;
  list-style: none;
  line-height: 20px;
}
.links a {
  text-decoration: none;
  color: rgb(35, 35, 92);
}
.copy_right {
  width: 100%;
  text-align: center;
  background: #071127;
  color: aliceblue;
  font-size: 15px;
  padding: 20px 0;
}

@media (max-width: 700px) {
  .row1 {
    flex-direction: column;
  }
  .row1 h2 {
    margin: 0%;
  }
  .links ul {
    width: 30vw;
    margin: 1% 0%;
  }
  .links{
    padding-left:  20%;
  }
  .first {
    width: 80%;
  }

  .first input {
    width: 52%;
    font-size: small;
    padding: 5px 10px;
  }
  .first button {
    font-size: small;
    padding: 5px;
  }
  .office {
    flex-basis: auto;
  }
  .office h2 {
    margin: 1.5% 0%;
  }
  .office_links {
    margin: auto;
    margin-top: 30px;
    padding: 0%;
    width: 85%;
    justify-content: space-between;
  }
}

/* ----drop down ---- */
#table_data {
  text-align: left;
  margin-bottom: 20px;
}
